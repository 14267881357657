const breakpoints = ["52em", "69em", "80em", "100em"]

export const mq = {
  xs: `@media screen and (min-width: ${breakpoints[0]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]})`,
  md: `@media screen and (min-width: ${breakpoints[2]})`,
  lg: `@media screen and (min-width: ${breakpoints[3]})`,
  xlg: `@media screen and (min-widt  h: ${breakpoints[4]})`,
}

const fontSizes = {
  p: "1.125rem",
  pSmall: "0.75rem",
  h1: "3.75rem",
  h1Small: "1.4rem",

  h2: "3.125rem",
  h2Small: "1.4rem",
  h3: "2.25rem",
  h3Small: "1.5rem",
  h4: "1.85rem",
  h5: "1.125rem",
}

const space = {
  0: 0,
  1: 5,
  2: 10,
  3: 28,
  4: 40,
  5: 80,
  6: 130,
  7: 200,
  8: 256,
}

export const fontWeights = {
  regular: 300,
  bold: 900,
}

const textTransforms = {
  uppercase: "uppercase",
  lowercase: "lowercase",
  capitalize: "capitalize",
}

const fonts = {
  headlines: "'Franklin ITC Pro', Times New Roman",
  instagram: "Arial, Helvetica, sans-serif",
  body: "'Franklin ITC Pro',  sans-serif",
}

const borders = [
  0,
  "1px solid #e6e6e6",
  "2px solid #e6e6e6",
  "1px solid white",
  "3px solid",
  "8px solid",
  "16px solid",
  "32px solid",
]

const lineHeights = {
  solid: 1,
  tight: 1.5,
  title: 1.25,
  copy: 1.5,
}

export const gridCols = {
  "4col": {
    display: "grid",
    gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(4, 1fr)"],
  },
  "3col": {
    display: "grid",
    gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
  },
  "2col": {
    display: "grid",
    gridTemplateColumns: ["repeat(2,1fr)", "repeat(2, 1fr)"],
  },
}

export const colors = {
  red: "#E42313",
  grayDark: "#101010",
  gray: "#212126",
  grayLight: "#F1F1F5",
  blue: "#3592FF",
  black: "#060606",
}

const theme = {
  fontSizes,
  fonts,
  borders,
  lineHeights,
  fontWeights,
  space,
  colors,
  textTransforms,
  breakpoints,
  mq,
  gridCols,
}

export default theme
